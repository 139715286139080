<template>
    <div>
        <div v-if="authUserPermission['message-view']">
            <base-header class="pb-6">
                <div class="row align-items-center py-4">
                    <div class="col-lg-6 col-7">
                        <h6 class="h2 text-white d-inline-block mb-0"></h6>
                    </div>      
                </div>
            </base-header>
            <div class="container-fluid mt--6">
                <div class="card mb-4">
                    <div class="card-header mt--4 mb--4">
                        <div class="row">
                            <div class="col">
                                <h3 class="mb-0">{{ tt('inbox') }}</h3>
                            </div>
                            <div class="col-6">
                                <base-input input-classes="form-control-sm text-center border-radius-20" v-model="table.search" :placeholder="tt('search_message')" v-on:keyup="filter"/>
                            </div>
                            <div class="col text-right">
                                <base-button size="sm" type="default" @click="create" v-if="authUserPermission['message-compose']">{{ tt('compose') }}</base-button>
                            </div>
                        </div>
                    </div>
                    <div>
                        <el-table height="350px" class="table-responsive table-flush" header-row-class-name="thead-light" :data="table.data" v-if="!onLoad" :row-class-name="tableRowClassName">
                            <el-table-column :label="tt('sender')" :prop="tt('sender')" sortable>
                                <template v-slot="{row}">
                                    {{row.sender_message}}
                                </template>
                            </el-table-column>
                            <el-table-column :label="tt('subject')" :prop="tt('subject')" sortable >
                                <template v-slot="{row}">
                                    {{row.subject}}
                                </template>
                            </el-table-column>
                            <el-table-column :label="tt('reply')" :prop="tt('reply')" sortable >
                                <template v-slot="{row}">
                                    {{row.reply}}
                                </template>
                            </el-table-column>
                            <el-table-column :label="tt('date')" :prop="tt('date')" sortable >
                                <template v-slot="{row}">
                                    {{row.updated_at}}
                                </template>
                            </el-table-column>
                            <el-table-column prop="action" width="100">
                                <template v-slot="{row}">
                                    <el-dropdown trigger="click" class="dropdown" @command="handleTableAction" :hide-on-click="true">
                                        <span class="btn btn-sm btn-icon-only text-light">
                                            <i class="fas fa-ellipsis-v mt-2"></i>
                                        </span>
                                        <el-dropdown-menu class="dropdown-menu dropdown-menu-arrow show" slot="dropdown">
                                            <router-link :to="'/message/view/'+row.message_id" class="dropdown-item" tag="button" v-if="authUserPermission['message-show']">
                                                <span class="ml-1">
                                                    {{ tt('view') }}
                                                </span>
                                            </router-link>
                                            <el-dropdown-item v-if="authUserPermission['message-delete']" :command="{action:'remove', data:row.message_id}">{{ tt('delete') }}</el-dropdown-item>  
                                        </el-dropdown-menu>
                                    </el-dropdown>
                                </template>
                            </el-table-column>
                        </el-table>
                        <page-loading v-else/>
                    </div>
                    <div class="card-footer pb-0 ">   
                        <span class="float-left">
                            <base-pagination :page-count="table.page.last_page" v-model="table.page.current_page" @click.native="changePage(table.page.current_page)" size="sm"></base-pagination>
                        </span>                             
                        <span class="float-right">
                            {{ tt('page_info', {size: (table.page.current_page - 1) * table.page.per_page + table.data.length, total: table.total}) }}
                        </span>
                    </div>
                </div>
            </div>            
            <modal :show.sync="form.show" size="lg">
                <template slot="header">
                    <h5 class="modal-title">{{form.title}}</h5>
                </template>
                <div>
                    <base-alert type="danger" v-if="errorMessage">
                        <p><strong>{{ errorMessage.message }}</strong></p>
                        <div v-if="errorMessage.data.length != 0">
                            <span v-for="message in errorMessage.data">
                                {{ message[0] }}<br>
                            </span>
                        </div>
                    </base-alert>
                    <div class="form-group row mb-0">
                        <label class="col-2 col-form-label">{{ tt('from') }}</label>
                        <label class="col-10 col-form-label">
                            {{authUser.name}}
                        </label>                        
                    </div>    
                    <hr class="mt-0 mb-1">
                    <div class="form-group row mb-0">                                        
                        <label class="col-2 col-form-label">{{ tt('to') }}</label>
                        <div class="col-10">
                            <el-select v-model="message.to" multiple filterable remote reserve-keyword :placeholder="tt('choose_to')" :remote-method="getTo" :loading="inputTo.loading">
                                <el-option v-for="item in to" :key="item.id" :label="item.name" :value="item.namecode"></el-option>
                            </el-select>                            
                        </div>                                                
                    </div>
                    <hr class="mt-0 mb-1">
                    <div class="form-group row mb-0">
                        <div class="col-12">
                            <input type="text" class="text-left form-control border-0 input-sm ml--2" :placeholder="tt('subject')" v-model="message.subject">                            
                        </div>
                    </div>
                    <hr class="mt-0 mb-1">
                    <div class="form-group row mb-0">
                        <div class="col-12">                                
                            <textarea class="form-control border-0 ml--2" :placeholder="tt('message')" rows="8" v-model="message.reply">
                            </textarea>                                
                        </div>
                    </div>
                </div>
                <template slot="footer">
                    <base-button type="secondary" @click="form.show = false">{{ tt('close') }}</base-button>
                    <base-button type="primary" @click="save" :disabled="btnSave.onLoading">{{ tt('send_message') }}</base-button>
                </template>
            </modal>            
        </div>
        <noaccess v-else/>
    </div>
</template>

<script>
    import {mapState} from 'vuex'
    import Api from '@/helpers/api';
    import message from '@/services/message/message.service';
    import account from '@/services/manage/manageAccount.service';
    export default {        
        data() {
            return {       
                onLoad: true,         
                form: {
                    title: this.tt('compose_message'),
                    show: false
                },     
                btnSave: {
                    onLoading: false
                },   
                table: {
                    data: [],
                    total: 0,
                    search: null,
                    page: {
                        current_page: 1,
                        per_page: 100,
                    },
                },  
                errorMessage: null,    
                inputTo: {
                    loading: false,
                    loadTimeout: null
                },  
                to: [],  
                message: {}
            }
        },
        computed: {
            ...mapState({
                authUserPermission :state => state.auth.userPermission,
                authUser :state => state.auth.user
            }),
        },
        mounted() {
            this.get();
        },
        methods: {
            handleTableAction(command) {
                switch (command.action) {   
                    case 'remove':
                            this.remove(command.data);
                        break;
                    default:
                        break;
                }
            },
            getTo(query) {                        
                clearTimeout(this.inputTo.loadTimeout);             
                let context = this;   
                this.inputTo.loadTimeout = setTimeout(() => {
                    this.inputTo.loading = true;
                    Api(context, account.get(this.table.page.current_page, {search: query})).onSuccess(function(respoonse) {
                        context.to              = respoonse.data.data.data.data;                        
                        context.inputTo.loading = false;                       
                    }).onError(function(error) {
                        context.to = [];
                        context.inputTo.loading = false;
                    }).call();             
                })                
            },
            get() {
                let context = this;
                Api(context, message.get(this.table.page.current_page, {search: this.table.search})).onSuccess(function(response) {
                    context.table.data  = response.data.data.data.data;
                    context.table.total = response.data.data.data.total;
                    context.table.page  = response.data.data.data;
                    context.onLoad      = false;  
                    // console.log( response.data.data.data.data);
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.table.data = [];
                        context.table.total = 0;
                        context.onLoad = false;
                    }
                }).call();
            },
            create() {
                this.form.title = this.tt('compose_message');
                this.form.show = true;
                this.message = {};
            },
            save() {                      
                this.btnSave.onLoading = true;
                this.errorMessage = null;
                let api = null;
                let context = this;
                this.message.recipient = this.message.to;
                api = Api(context, message.compose(this.message)).onSuccess(function(response) {
                    context.get();
                    context.$notify({
                        message: response.data.message,                  
                        type: 'success',
                        verticalAlign: 'bottom', 
                        horizontalAlign: 'left'
                    });
                    context.form.show = false;
                }).onError(function(error) {                    
                    context.errorMessage = error.response.data;
                }).onFinish(function() {
                    context.btnSave.onLoading = false;   
                })
                .call();
            },
            edit(id) {
                this.form.title = "Edit Source Type";
                this.form.show = true;
            },
            remove(id) {
                this.confirmDialog(this.tt('confirm_delete')).then((result) => {
                    let context = this;
                    if (result.value) {
                        Api(context, message.delete(id)).onSuccess(function(response) {
                            context.$notify({
                                message: response.data.message,
                                type: 'success',
                                verticalAlign: 'bottom', 
                                horizontalAlign: 'left'
                            });
                            context.get();
                        }).call();
                    }
                })
            },
            filter() {
                this.onLoad = true;
                this.table.page.current_page = 1;
                clearTimeout(this.loadTimeout);
                this.loadTimeout = setTimeout(() => {
                    this.get()
                }, 100);
            },
            changePage(page) {
                let context = this;
                context.onLoad = true;

                clearTimeout(this.loadTimeout);
                this.loadTimeout = setTimeout(() => {
                    this.get()
                }, 100);
            },
            tableRowClassName({row, rowIndex}) {
                if (row.reads === 0) {
                  return 'custom-row';
                }

                return '';
            }
        }   
    };
</script>

<style>
    div > .el-table .custom-row {
        background-color: #ededed;
    }
</style>
